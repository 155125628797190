import React from "react"
import styled from "styled-components"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import GlobalStyles from "../GlobalStyles"

const Wrapper = styled.div`
  background: #2D3137;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  font-size: 240px;
  color: white;
  font-family: "Work Sans", sans-serif;
  font-weight: 300;
  margin: 0;
  text-align: center;
`

const Subtitle = styled.h3`
  font-size: 50px;
  color: white;
  font-weight: 300;
  font-family: "Work Sans", sans-serif;
  text-align: center;
`

const page404 = () => (
  <>
    <GlobalStyles />
    <Header />
      <Wrapper>
        <div>
          <Title>
            404
          </Title>
          <Subtitle>
            Strona, na którą próbujesz wejść nie istnieje.
          </Subtitle>
        </div>
      </Wrapper>
    <Footer />
  </>
)

export default page404;